import React, { useState } from "react";
import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification, Select, Space } from "antd";
import { useHistory } from "react-router-dom";
import { GlobalVariables } from "src/resources/GlobalVariables";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { AdminService } from "src/resources/AdminService";

export default function KayitFormu() {
  const router = useHistory();
  const [form] = Form.useForm();
  const [isAllergic, setIsAllergic] = useState(false);
  const { lg } = useBreakpoint();

  const onFinish = (values) => {
    console.log(values);
    AdminService.newStudent(values).then((data) => {
      notification.success({
        message: "Success!",
        description: "Student saves successfully!",
      });
      onReset();
      router.replace("/completed");
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const allergieChanged = (v) => {
    console.log(v);

    v === "Yes" ? setIsAllergic(true) : setIsAllergic(false);
  };
  return (
    <div className="container mx-auto min-h-screen">
      <div className="py-6  overflow-hidden lg:pb-24">
        <img
          className="relative mx-auto mb-4"
          width={250}
          src={require("../assets/images/logo.png").default}
          alt="logo"
        />
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative">
            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-amazonl sm:text-4xl">
              YAZ KUR'AN KURSU PROGRAMI
              <br />
              KAYIT FORMU
            </h2>
            <br />
          </div>
          <div className="border border-gray-300 bg-f5f5f5 p-2 lg:p-8 shadow-lg mx-auto max-w-4xl">
            <Form
              form={form}
              onFinish={onFinish}
              onReset={onReset}
              layout={lg ? "horizontal" : "vertical"}
              {...GlobalVariables.formLayout}
            >
              <Form.Item
                label="Student fullname"
                name="fullname"
                rules={[{ required: true, message: "Fullname is required!" }]}
              >
                <Input placeholder="Please type student fullname" />
              </Form.Item>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[{ required: true, message: "Gender is required!" }]}
              >
                <Select placeholder="Please select gender">
                  {["Male", "Female"].map((g) => (
                    <Select.Option key={g} value={g}>
                      {g}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Year Level"
                name="year"
                rules={[{ required: true, message: "Year Level is required!" }]}
              >
                <Select placeholder="The year level of the child in 2021 ">
                  {[
                    "Kindergarten",
                    "Year 1",
                    "Year 2",
                    "Year 3",
                    "Year 4",
                    "Year 5",
                  ].map((g) => (
                    <Select.Option key={g} value={g}>
                      {g}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Qur'an Level"
                name="level"
                rules={[
                  { required: true, message: "Qur'an Level is required!" },
                ]}
              >
                <Input placeholder="With a few sentences briefly explain the Qur'an level of your child" />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Does your child <br />
                    have any allergies?
                  </span>
                }
                name="isAllergie"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Select placeholder="Please select" onChange={allergieChanged}>
                  {["Yes", "No"].map((g) => (
                    <Select.Option key={g} value={g}>
                      {g}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {isAllergic && (
                <Form.Item
                  label="Allergie details"
                  name="allergie"
                  rules={[{ required: true, message: "Details is required!" }]}
                >
                  <Input placeholder="Please type allergie details" />
                </Form.Item>
              )}
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p>
                  Does your child have a health condition that supervisors need
                  to be aware of?
                </p>
              </Form.Item>
              <Form.Item
                className="-mt-4"
                name="health"
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <Input placeholder="Please type health conditions (if any)" />
              </Form.Item>
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p className="font-semibold">Guardian Details</p>
              </Form.Item>
              <Form.Item
                className="-mt-4"
                label="Guardian Fullname"
                name="gfullname"
                rules={[{ required: true, message: "Fullname is required!" }]}
              >
                <Input placeholder="Please type fullname" />
              </Form.Item>
              <Form.Item
                label="Guardian Phone"
                name="gphone"
                rules={[{ required: true, message: "Phone is required!" }]}
              >
                <Input placeholder="Please type phone" />
              </Form.Item>
              <Form.Item
                label="Guardian Email"
                name="email"
                rules={[{ required: true, message: "Email is required!" }]}
              >
                <Input placeholder="Please type contact email" />
              </Form.Item>
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p className="font-semibold">
                  Emergency Contact Details (Different then the guardian)
                </p>
              </Form.Item>
              <Form.Item
                className="-mt-4"
                label="Contact Fullname"
                name="efullname"
                rules={[{ required: true, message: "Fullname is required!" }]}
              >
                <Input placeholder="Please type fullname" />
              </Form.Item>
              <Form.Item
                label="Contact Phone"
                name="ephone"
                rules={[{ required: true, message: "Phone is required!" }]}
              >
                <Input placeholder="Please type phone" />
              </Form.Item>
              <Form.Item {...GlobalVariables.tailLayout} className="mt-6">
                <Space>
                  <Button
                    type="default"
                    htmlType="reset"
                    icon={<ClearOutlined />}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
          <svg
            className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
