import React, { useState } from "react";
import {
  ArrowRightOutlined,
  CheckOutlined,
  ClearOutlined,
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";

export default function HomePage() {
  const router = useHistory();
  const [form] = Form.useForm();
  const [panel, setPanel] = useState(false);

  const onFinish = (values) => {
    console.log(values);
    if (values.answer === false) {
      values = { ...values, students: [] };
    }
    AdminService.sendSurvey(values).then((data) => {
      notification.success({
        message: "Başarılı",
        description: "Anket başarı ile gönderildi.",
      });
      onReset();
      router.push("/completed");
    });
  };

  const onReset = () => {
    form.resetFields();
  };
  const radioChanged = (v) => {
    console.log(v.target.value);
    setPanel(v.target.value);
  };
  return (
    <div className="container mx-auto min-h-screen">
      <div className="py-6  overflow-hidden lg:pb-24 ">
        <img
          className="relative mx-auto mb-4"
          width={250}
          src={require("../assets/images/logo.png").default}
          alt="logo"
        />
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative">
            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-mavi sm:text-4xl">
              KUR'AN KURSU PROGRAMI
              <br />
              KATILIM ANKET FORMU
            </h2>
            {/* <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
              Lütfen gerekli açıklamaları okuyun ve kayıt için formu doldurun.
            </p> */}
          </div>

          <div className="relative mt-12  lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <h3 className="text-2xl font-bold text-mavi tracking-tight sm:text-3xl mb-4">
                BİLGİLENDİRME
              </h3>
              <div className="space-y-4 text-base">
                <p className="text-kirmizi">
                  <CheckOutlined />
                  <b> Bu form bir kayit formu değildir.</b>
                </p>
                <p className="font-semibold">
                  <CheckOutlined /> Sadece göstereceğiniz ilgiye göre, kaç
                  öğretmenin istihdam edilmesi gerektiğini önceden tespit etmek
                  amaçlı bir ankettir.
                </p>
                <p>
                  <CheckOutlined />
                  <b> Galaxy Aile içi Rehberlik (Galaxy AIR)</b> grubu olarak,
                  okul yılı boyunca sürecek Kuran Kursu vermeyi planlıyoruz.
                  Kursumuz ogretmen ihtiyacını karşılayabilirsek 2.dönemden
                  itibaren başlayacak ve yıl sonuna kadar devam edecektir.
                </p>
                <Descriptions bordered column={1} size="small" className="-m-2">
                  <Descriptions.Item label="Yer">
                    Amity College Prestons Kampüsü
                  </Descriptions.Item>
                  <Descriptions.Item label="Zaman">
                    Her Cumartesi, <br /> 1.Ders : 09:00 - 11:00 <br /> 2.Ders :
                    11:00 - 13:00
                  </Descriptions.Item>
                  <Descriptions.Item label="Yaş">
                    Bu yıl 1.sınıf'tan 5.sınıfa kadar olanlar
                  </Descriptions.Item>
                </Descriptions>{" "}
                <br />
                <p>
                  <CheckOutlined /> Her öğrenci için dönem başına $100-$200
                  aralığında olacağı tahmin edilen bir kurs ücreti olacaktır.
                  İmkanı olmayanlar için sponsorlar bulacağız.
                </p>
                <p>
                  <CheckOutlined /> Lütfen bu formu (anketi){" "}
                  <b> en geç 24 Mart 2022 </b> tarihine kadar doldurunuz.
                </p>
                <p className="text-base">
                  <CheckOutlined /> Kur'an Eğitiim müfredatını görmek için{" "}
                  <a
                    className="text-sky-500"
                    href="https://docs.google.com/spreadsheets/d/1a1nyhdDaxQ7D-Uk_n8ex5ImA9Kvhri6X/edit?usp=sharing&ouid=104838901607857566609&rtpof=true&sd=true"
                    target="_blank"
                  >
                    tıklayınız.
                  </a>
                </p>
              </div>
              <br />
              <Divider orientation="left">Anket Formu</Divider>
              <Form
                form={form}
                onFinish={onFinish}
                onReset={onReset}
                layout="vertical"
                initialValues={{ students: [{}] }}
              >
                <Form.Item label="Veli adı" name="firstname">
                  <Input placeholder="Adınızı yazınız" />
                </Form.Item>
                <Form.Item label="Veli soyadı" name="lastname">
                  <Input placeholder="Soyadınızı yazınız" />
                </Form.Item>
                <Form.Item
                  label="Çocuğunuzu ya da çocuklarınızı kursumuza göndermek ister misiniz?"
                  name="answer"
                  rules={[{ required: true, message: "Bu bilgi gereklidir" }]}
                >
                  <Radio.Group
                    name="radiogroup"
                    defaultValue={1}
                    onChange={radioChanged}
                  >
                    <Radio value={true}>Evet</Radio>
                    <Radio value={false}>Hayır</Radio>
                  </Radio.Group>
                </Form.Item>
                {panel && (
                  <Form.List name="students">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "first"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Ad gereklidir",
                                },
                              ]}
                            >
                              <Input placeholder="Öğrenci adı" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "last"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Soyad Gereklidir",
                                },
                              ]}
                            >
                              <Input placeholder="Soyad" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "year"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Sınıf gereklidir",
                                },
                              ]}
                            >
                              <Select placeholder="Sınıf">
                                {[
                                  "Year-1",
                                  "Year-2",
                                  "Year-3",
                                  "Year-4",
                                  "Year-5",
                                ].map((y) => (
                                  <Select.Option key={y} value={y}>
                                    {y}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <DeleteOutlined
                              style={{ fontSize: "24px", color: "red" }}
                              onClick={() => remove(name)}
                            />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Öğrenci Ekle
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                )}
                <Form.Item className="mt-6">
                  <Space>
                    <Button
                      type="default"
                      htmlType="reset"
                      icon={<ClearOutlined />}
                    >
                      Temizle
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SaveOutlined />}
                    >
                      Anketi Gönder
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                />
              </svg>
              <img
                className="relative mx-auto w-full p-2 border border-gray-300 shadow-lg"
                src={require("../assets/images/start.jpg").default}
                alt=""
              />
            </div>
          </div>

          <svg
            className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
